import http from '@/common/js/http'


// 新增充值审核信息
export const rechargeAuditInfoAdd = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/rechargeAuditInfoAdd', form, "POST");
};
// 查询司机充值信息
export const selectDriverRechargeInfo = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/selectDriverRechargeInfo', form, "POST");
};

// 查询车辆充值信息
export const selectCarRechargeInfoApi = async (form) => {
    return await http('/galaxyRechargeAuditInfoApi/selectCarRechargeInfo', form, "POST");
};


