<style lang="scss" scoped>
.eTrip-section-cont /deep/ {
    .el-form-item__label {
        padding-right: 0;
    }

    .el-form-item--small.el-form-item {
        margin-bottom: 15px;
    }
}

.vehicle-service-list {
    background: #fff;
    margin-top: 10px;
    padding: 15px;
    font-size: 14px;
    min-height: 300px;

    h4 {
        color: #909399;
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 10px;
    }

    .service-list_li {
        display: flex;
        margin-bottom: 25px
    }

    .list_li_title {
        width: 80px
    }

    .list_li_content {
        width: calc(100% - 80px)
    }

    .list_li_everyone {
        width: 120px;
        display: inline-block;
    }

    .list_li_subtotal {
        font-weight: bold;
        margin-left: 10px;
    }

    .list_add_btn {
        margin: 10px 0;
    }

    .service-list_driver {
        background: #f8f8f8;
        position: relative;
        display: flex;
        align-items: center;
        height: 44px;
        margin-bottom: 5px;

        p {
            padding-left: 15px;
        }
    }

    .list_driver_1 {
        width: 30px;
    }

    .list_driver_2 {
        min-width: 40px;

        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }
    }

    .list_driver_3 {
        width: 100px;
    }

    .list_driver_5 {
        width: 120px;
    }

    .driver-term-validity {
        background: #fff;
        display: inline-block;
        margin-left: 15px;

        .el-icon-arrow-right {
            vertical-align: middle;
            margin: 0 10px;
            color: #909399;
        }

        &.disabled {
            background: #F5F7FA;
        }

        .disabled-span {
            color: #C0C4CC;
        }
    }

    .list_driver_del {
        position: absolute;
        right: 0;
        border: 0;
        padding: 0;
        height: 44px;
        width: 44px;
        border-radius: 0;

        i {
            font-size: 20px;
        }
    }
}

.vehicle-service-foot {
    display: flex;
    align-items: center;
    padding-right: 15px;

    .service-foot_left {
        width: 180px;
        color: #409EFF;
        font-weight: bold
    }

    .service-foot_right {
        width: calc(100% - 180px);
        text-align: right
    }

    .foot_right_content {
        display: flex;
        align-items: center;
        justify-content: flex-end
    }

}

.eTrip-section-cont /deep/ {
    .driver-term-validity {
        padding-left: 15px;

        .el-input__inner {
            border: 0;
        }

        .el-icon-time {
            display: none;
            opacity: 0;

        }

        .el-input__prefix, .el-input__suffix {
            display: none;
        }

        .el-input--prefix .el-input__inner {
            padding-left: 0;
        }

        .el-input--suffix .el-input__inner {
            padding-right: 0;
        }

        .el-form-item--small.el-form-item, .el-form-item--mini.el-form-item {
            margin-bottom: 0;
        }
    }
}

.vehicle-serve-state {
    background: #fff;
    padding: 12px;

    p {
        display: inline-block;
        width: 100px;
        font-weight: bold;
    }
}


</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <ul class="vehicle-serve-state" style="" v-if="type === '3'">
                    <li>
                        <p>审核状态：</p>
                        {{ payStatusName }}
                    </li>
                    <li v-if="form.payStatus === 2 ">
                        <p>未通过原因：</p>
                        {{ form.remark }}
                    </li>
                </ul>
                <el-form :model="form" :rules="rules" ref="form" size="small">
                    <div style="background: #fff;margin-top: 10px;padding-top: 15px">
                        <el-form-item label="服务类型：" required prop="productType"
                                      label-width="90px">
                            <el-select v-model="form.productType" @change="handleProductTypeChange" style="width: 260px"
                                       placeholder="请选择服务类型"
                                       :disabled="disabled">
                                <el-option v-for="item in rechargeProductTypes" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="运营商："
                                      label-width="90px"
                                      prop="operatorId"
                                      :rules="{required: true, message: '请填写运营商', trigger: 'change'}"
                                      style="width: 350px"
                                      v-if="userInfo.userType==='0'">
                            <operator-select
                                v-model="form.operatorId"
                                @clear="handleClear"
                                :disabled="disabled"
                                @change="changeOperator"
                            ></operator-select>
                        </el-form-item>
                        <el-form-item label="供应商："
                                      label-width="90px"
                                      prop="supplierId"
                                      :rules="{required: true, message: '请填写供应商', trigger: 'change'}"
                                      style="width: 350px"
                                      v-if="userInfo.userType!=='1'">
                            <supplier-select
                                v-model="form.supplierId"
                                :operatorId="form.operatorId"
                                :disabled="disabled"
                                @change="changeSupplier"
                                userType="2"></supplier-select>
                        </el-form-item>
                        <div style="display: flex">
                            <el-form-item label="支付方式："
                                          label-width="90px"
                                          prop="payType"
                                          :rules="{required: true, message: '请填写支付方式', trigger: 'change'}"
                                          style="width: 350px">
                                <el-select v-model="form.payType"
                                           style="width: 100%"
                                           placeholder="请选择支付方式"
                                           :disabled="disabled">
                                    <el-option
                                        v-for="item in payTypeList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="支付流水号："
                                          label-width="110px"
                                          prop="transactionId"
                                          :rules="{required: true, message: '请填写支付流水号', trigger: 'blur'}"
                                          style="width: 350px">
                                <el-input v-model="form.transactionId"
                                          :disabled="disabled" placeholder="请填写支付流水号"></el-input>
                            </el-form-item>
                            <el-form-item label="支付金额："
                                          label-width="100px"
                                          prop="payPrice"
                                          :rules="{required: true, message: '请填写支付金额', trigger: 'blur'}"
                                          style="width: 260px">
                                <el-input v-model="form.payPrice"
                                          :disabled="disabled" placeholder="请填写支付金额">
                                    <span slot="suffix" style="color: #333;right: 15px">元</span>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="vehicle-service-list" v-loading="loading">
                        <div v-if="supplierDriverList.length">
                            <h4>
                                在您需要的充值类型下，添加{{
                                    typeTitle
                                }}信息。可同时选择多种类型、多{{ form.productType === 0 ? '位' : '' }}{{ typeTitle }}</h4>
                            <ul>
                                <li class="service-list_li" v-for="(list, index) in serviceList" :key="index">
                                    <div class="list_li_title">
                                        {{ list.title }}：
                                    </div>
                                    <div class="list_li_content">
                                        <div v-if="list.type === 0">
                                            <span class="list_li_everyone">¥{{ monthlyPaymentPrice }}/人</span><span
                                            class="list_li_subtotal">当前小计：¥{{
                                                form.rechargeMonthPrice | currency
                                            }}</span>
                                        </div>
                                        <div v-if="list.type === 1">
                                            <span class="list_li_everyone">¥{{ quarterlyPaymentPrice }}/人</span><span
                                            class="list_li_subtotal">当前小计：¥{{
                                                form.rechargeQuarterlyPrice | currency
                                            }}</span>
                                        </div>
                                        <div v-if="list.type === 2">
                                            <span class="list_li_everyone">¥{{ yearlyPaymentPrice }}/人</span><span
                                            class="list_li_subtotal">当前小计：¥{{
                                                form.rechargeYearlyPrice | currency
                                            }}</span>
                                        </div>
                                        <el-button type="primary" size="small" class="list_add_btn"
                                                   v-if="!disabled"
                                                   @click="addDriver(list.key)">+ {{ typeTitle }}
                                        </el-button>
                                        <ul>
                                            <li class="service-list_driver"
                                                v-for="(item, idx) in form[list.key]"
                                                :key="idx">
                                                <p class="list_driver_1">{{ idx + 1 }}</p>
                                                <p class="list_driver_2">
                                                    <el-form-item label=""
                                                                  :prop="list.key + '.' + idx + '.driverId'"
                                                                  size="mini"
                                                                  :rules="{validator: checkDriver, trigger: 'change'}">
                                                        <el-select v-model="item.driverId"
                                                                   filterable
                                                                   :placeholder="typeTitle==='司机'?'请输入司机姓名或手机号':'请输入车牌号'"
                                                                   size="mini"
                                                                   :disabled="disabled"
                                                                   @change="changeSelectDriver(item.driverId, idx, list.key)">
                                                            <el-option
                                                                v-for="driverList in supplierDriverList"
                                                                :key="driverList.value"
                                                                :label="driverList.label"
                                                                :value="driverList.value">
                                                            <span style="float: left">{{
                                                                    form.productType === 0 ? driverList.driverName : driverList.carNumberPlate
                                                                }}</span>
                                                                <span
                                                                    style="float: right; color: #8492a6; font-size: 13px"
                                                                    v-if="form.productType === 0">{{
                                                                        driverList.mobile
                                                                    }}</span>
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </p>
                                                <p style="width: 90px" v-if="form.productType===0">
                                                    {{ item.carNumberPlate }}</p>
                                                <p style="width: 100px" class="list_driver_5">
                                                    {{ item.carType | seatModelDesc }}</p>
                                                <div style="width: 140px" v-if="form.productType===1">
                                                    <span>{{ item.driverName }}&nbsp;</span>
                                                    <span>{{ item.mobile }}</span>
                                                </div>
                                                <p>{{ item.isRenew ? '续费' : '新增' }}</p>
                                                <div class="driver-term-validity" :class="disabled ? 'disabled':''">
                                                    <div style="display: flex;align-items: center">
                                                        <p style="width: 60px"> 有效期：</p>
                                                        <p>
                                                            <el-form-item label=""
                                                                          :prop="list.key + '.' + idx + '.validityTime'"
                                                                          size="mini"
                                                                          :rules="{required: true, message: '请选择有效期', trigger: 'blur'}">
                                                                <div style="display: flex;align-items: center;">
                                                                    <el-date-picker
                                                                        v-model="item.validityTime[0]"
                                                                        style="width: 130px;"
                                                                        clearable
                                                                        disabled
                                                                        format="yyyy-MM-dd 00:00:00">
                                                                    </el-date-picker>
                                                                    <span style="margin: 0 5px;">至</span>
                                                                    <el-date-picker
                                                                        v-model="item.validityTime[1]"
                                                                        :disabled="disabled"
                                                                        style="width: 130px;"
                                                                        clearable
                                                                        format="yyyy-MM-dd 23:59:59"
                                                                        @change="changeValidityTime(idx, list.key)"
                                                                        :picker-options="{
                                                                            disabledDate:disabledDate.bind(this,item.validityTime[0])
                                                                        }">
                                                                    </el-date-picker>
                                                                    <span style="padding-right: 10px">{{
                                                                            dayjs(item.validityTime[1]).diff(item.validityTime[0], 'd')
                                                                        }}天</span>
                                                                </div>
                                                            </el-form-item>
                                                        </p>
                                                    </div>
                                                </div>
                                                <el-button class="list_driver_del"
                                                           v-if="!disabled"
                                                           @click="deleteDriver(idx, list.key)">
                                                    <i class="el-icon-error"></i>
                                                </el-button>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <el-empty v-else description="当前供应商无可充值的车辆或服务"/>
                    </div>
                </el-form>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="supplierDriverList.length">
            <div class="eTrip-footer-cont vehicle-service-foot">
                <div class="service-foot_left">
                    车辆服务充值 {{ titleTips }}
                </div>
                <div class="service-foot_right">
                    <div class="foot_right_content">
                        <div style="margin-right: 15px;">
                            <p style="font-weight: bold">
                                当前充值金额：包月¥{{ form.rechargeMonthPrice }}/{{
                                    form.rechargeMonthPeople
                                }}{{ form.productType === 0 ? '人' : '车' }}
                                +
                                包季¥{{ form.rechargeQuarterlyPrice }}/{{
                                    form.rechargeQuarterlyPeople
                                }}{{ form.productType === 0 ? '人' : '车' }}
                                +
                                包年¥{{ form.rechargeYearlyPrice }}/{{
                                    form.rechargeYearlyPeople
                                }}{{ form.productType === 0 ? '人' : '车' }}
                                =
                                合计¥{{ form.rechargeTotalPrice }}/{{
                                    form.rechargeTotalPeople
                                }}{{ form.productType === 0 ? '人' : '车' }}
                            </p>
                            <p style="font-size: 12px;color: #909399" v-if="type === '0'">请确认合计充值金额 &le;
                                对公打款金额</p>
                        </div>

                        <el-popover
                            placement="top"
                            width="160"
                            v-model="submitShow"
                            v-if="type === '0'">
                            <p>确认提交</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="submitShow = false">取消</el-button>
                                <!--                                <el-button type="primary" size="mini" @click="submitBtn('0')">确定</el-button>-->
                                <el-button type="primary" size="mini" v-debounce="submitBtn">确定</el-button>
                            </div>
                            <el-button slot="reference" type="primary" size="small">提交</el-button>
                        </el-popover>
                        <el-button type="primary" @click="submitBtn('2', '0')" size="small"
                                   v-if="type === '2' && this.serveType === '0'">保存
                        </el-button>
                        <div v-if="type === '3' && form.payStatus === 0">
                            <el-button @click="auditBtn(2)" size="small">不通过</el-button>
                            <el-button type="primary" @click="auditBtn(1)" size="small">通过</el-button>
                        </div>
                    </div>


                </div>
            </div>
        </el-footer>
        <audit-adopt-dialog></audit-adopt-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
import operatorSelect from "@/www/components/operator-select/index"
import supplierSelect from "@/www/components/supplier-select/index"
import {payTypeList} from "../data"
import {rechargeProductTypes} from "@/data/index"
import moment from 'moment'
import auditAdoptDialog from "../dialog/index"
import {rechargeAuditInfoAdd, selectDriverRechargeInfo, selectCarRechargeInfoApi} from "@/www/urls/vehicleServiceUrls"
import {rechargeAuditInfoDetail, rechargeAuditInfoUpdate} from "@/www/urls/openAuditUrls"
import {getAuditStatusLabel} from "../data"
import {getSupplierIdOperatorInfo} from '@/urls/index';
import dayjs from "dayjs";


export default {
    //定义模版数据
    data() {
        let dateToday = Date.now();
        return {
            rechargeProductTypes,
            dateToday,
            payTypeList,
            titleTips: '查看',
            disabled: false,
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() < this.dateToday
                }
            },
            form: {
                productType: rechargeProductTypes[0].value,
                operatorId: '',
                supplierId: '',
                payType: 0,
                payWay: 1,
                transactionId: '',
                rechargeAuditDetailMonth: [],
                rechargeAuditDetailQuarterly: [],
                rechargeAuditDetailYearly: [],
                rechargeMonthPrice: '', //包月总费用 ,
                rechargeMonthPeople: '', //  包月总人
                rechargeQuarterlyPrice: '', //包季总费用 ,
                rechargeQuarterlyPeople: '', //包季总人 ,
                rechargeYearlyPrice: '', //包年总费用 ,
                rechargeYearlyPeople: '', //包年总人,
                rechargeTotalPrice: '', //所有充值总费用 ,
                rechargeTotalPeople: '', //所有充值人 ,
            },
            monthlyPaymentPrice: 0,  // 包月单价
            quarterlyPaymentPrice: 0,  //  包季单价
            yearlyPaymentPrice: 0,  //  包年单价
            remoteDriverList: [],  // 司机列表
            driverListLoad: false,
            supplierDriverList: [],  // 供应商下司机列表
            serviceList: [
                {
                    type: 0,
                    title: '包月服务',
                    everyonePrice: 0,
                    subtotal: '0',
                    key: 'rechargeAuditDetailMonth'
                },
                {
                    type: 1,
                    title: '包季服务',
                    everyonePrice: 0,
                    subtotal: '0',
                    key: 'rechargeAuditDetailQuarterly'
                },
                {
                    type: 2,
                    title: '包年服务',
                    everyonePrice: 0,
                    subtotal: '0',
                    key: 'rechargeAuditDetailYearly'
                }
            ],
            payStatusName: '',   //审核状态名
            submitShow: false,  //  二次确认
            rules: {},
            loading: false
        }
    },
    props: {
        type: {
            type: String,
            default: '1'
        },  // 0申请 1查看 2修改/重新提交 3审核
        auditId: {
            type: String,
            default: ''
        }
    },
    components: {
        operatorSelect,
        supplierSelect,
        auditAdoptDialog
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        },
        serveType() {
            return this.$route.params.type
        },
        typeTitle() {
            return this.form.productType === 0 ? '司机' : '车辆'
        }
    },
    //主件被加载完成
    mounted: function () {
        this.initData();
        this.searchRole();
    },
    //定义事件方法
    methods: {
        dayjs,
        disabledDate: (start, time) => {
            return time.getTime() < start
        },
        handleClear() {
            if (this.userInfo.userType === '0') {
                this.form.supplierId = '';
                this.form.supplierName = '';
                this.form.operatorId = '';
                this.form.operatorName = '';
                this.supplierDriverList = [];
                this.$refs['form'].clearValidate();
            }
        },
        initData() {
            switch (this.type) {
                case '0':
                    this.titleTips = '申请';
                    break;
                case '1':
                    this.titleTips = '查看';
                    this.disabled = true;
                    if (this.auditId) this.rechargeAuditInfoAudit(this.auditId);
                    break;
                case '2':
                    if (this.serveType === '0') {
                        this.titleTips = '修改'
                    }
                    if (this.serveType === '1') this.titleTips = '重新提交';
                    if (this.auditId) this.rechargeAuditInfoAudit(this.auditId);
                    break;
                case '3':
                    this.titleTips = '审核';
                    this.disabled = true;
                    if (this.auditId) this.rechargeAuditInfoAudit(this.auditId);
                    break;
            }
        },
        searchRole() {
            const userType = parseInt(this.userInfo.userType);
            if (userType !== 0) {
                this.getUserInfo(userType);
            }
        },
        getUserInfo(type) {
            switch (type) {
                case 1:
                    this.getOperatorInfo(this.userInfo.refId);
                    this.form.supplierId = this.userInfo.refId;
                    this.form.supplierName = this.userInfo.companyName;
                    this.getDriverList(this.userInfo.refId);
                    break;
                case 2:
                    this.form.operatorId = this.userInfo.refId;
                    this.form.operatorName = this.userInfo.companyName;
                    break;
            }
        },
        async getOperatorInfo(id) {
            const ret = await getSupplierIdOperatorInfo(id);
            if (ret) {
                this.form.operatorId = ret.operatorId;
                this.form.operatorName = ret.operatorName
            }
        },
        checkDriverList(array, id) {
            let number = 0
            array.forEach(item => {
                if (item.driverId === id) {
                    number++
                }

            })
            return number
        },
        checkDriver(rule, value, callback) {
            if (!value) {
                callback(new Error(`请选择${this.typeTitle}`));
            } else {
                let ret = this.checkDriverList(this.form.rechargeAuditDetailMonth, value);
                ret += this.checkDriverList(this.form.rechargeAuditDetailQuarterly, value);
                ret += this.checkDriverList(this.form.rechargeAuditDetailYearly, value);
                if (ret > 1) callback(new Error(`选择${this.typeTitle}重复`));
                else callback()
            }

        }, // 验证司机id
        handleProductTypeChange() {
            this.getDriverList(this.form.supplierId)
        },
        changeOperator(item) {
            this.form.operatorName = item.operatorName;
            this.form.supplierId = undefined;
            this.getDriverList(this.form.supplierId)
        },
        changeSupplier(item) {
            this.form.supplierName = item.supplierName;
            this.getDriverList(item.id)

        },  //供应商选择
        changeValidityTime(idx, key) {
            if (this.form[key][idx].validityTime && this.form[key][idx].validityTime.length)
                this.form[key][idx].validityTime = [dayjs(this.form[key][idx].validityTime[0]).startOf('d').valueOf(), dayjs(this.form[key][idx].validityTime[1]).endOf('d').valueOf()]
            let validityTime = this.form[key][idx].validityTime;
            if (validityTime) {
                this.form[key][idx].validityStartTime = +new Date(validityTime[0]);
                this.form[key][idx].validityEndTime = +new Date(validityTime[1]);
            }

            this.form[key] = JSON.parse(JSON.stringify(this.form[key]))

        },
        focusValidityTime(idx, key) {
            let validityTime = this.form[key][idx].validityTime;
            let nowTime = +new Date();
            let endTime = validityTime ? validityTime[0] : nowTime;
            if (validityTime < nowTime)
                endTime = nowTime;
            this.dateToday = moment(endTime).startOf('day').valueOf();
        },
        async getDriverList(supplierId) {
            if (!supplierId)
                return this.supplierDriverList = [];
            let form = {
                supplierId,
                productType: this.form.productType
            };
            this.loading = true
            const fn = form.productType === 0 ? selectDriverRechargeInfo : selectCarRechargeInfoApi;
            let res = await fn(form);
            this.loading = false
            if (res.success && res.data) {
                this.supplierDriverList = res.data;
                this.supplierDriverList.map(item => {
                    if (form.productType === 0) {
                        item.value = item.driverId;
                        item.label = item.driverName + '' + item.mobile;
                    } else {
                        item.value = item.carId;
                        item.driverId = item.carId;
                        item.label = item.carNumberPlate;
                    }
                    if (item.validityStartTime) item.isRenew = true;
                    else item.isRenew = false;
                })

                let serviceList = this.serviceList;
                if (this.supplierDriverList && this.supplierDriverList.length > 0) {
                    this.monthlyPaymentPrice = this.supplierDriverList[0].monthlyPaymentPrice;  // 包月单价
                    this.quarterlyPaymentPrice = this.supplierDriverList[0].quarterlyPaymentPrice;  //  包季单价
                    this.yearlyPaymentPrice = this.supplierDriverList[0].yearlyPaymentPrice;  //  包年单价
                }

                serviceList.forEach(item => {
                    this.handleSubtotal(item.key, this.form[item.key].length)
                })
            }
        },  // 获取供应商下司机列表
        changeSelectDriver(driverId, idx, key) {
            let driverList = this.supplierDriverList;
            driverList.forEach(item => {
                if (item.driverId == driverId) {
                    item.carType = parseInt(item.carType);
                    item.isRenew = !!item.validityStartTime;
                    let endTime,
                        startTime = !item.validityEndTime || dayjs(item.validityEndTime).isBefore(dayjs(), 'd') ? +new Date() :
                            dayjs(item.validityEndTime).add(1, 'd').valueOf();
                    switch (key) {
                        case 'rechargeAuditDetailMonth':
                            item.rechargePrice = this.monthlyPaymentPrice;
                            item.rechargeType = 0;
                            endTime = dayjs(startTime).add(30, 'd').valueOf();
                            break;
                        case 'rechargeAuditDetailQuarterly':
                            item.rechargePrice = this.quarterlyPaymentPrice;
                            item.rechargeType = 1;
                            endTime = dayjs(startTime).add(90, 'd').valueOf()
                            break;
                        case 'rechargeAuditDetailYearly':
                            item.rechargePrice = this.yearlyPaymentPrice;
                            item.rechargeType = 2;
                            endTime = dayjs(startTime).add(365, 'd').valueOf()
                            break;
                    }
                    item.validityTime = [startTime, endTime];
                    this.form[key][idx] = item;
                }

            });

            console.log(this.form[key], "this.form[key]---this.form[key]");
            this.form[key] = JSON.parse(JSON.stringify(this.form[key]))
        },  // 司机选中
        handleSubtotal(type, length) {
            // let totalPeople = null;
            switch (type) {
                case 'rechargeAuditDetailMonth':
                    if (length) {
                        this.form.rechargeMonthPrice = this.monthlyPaymentPrice * length;
                        this.form.rechargeMonthPeople = length;
                    } else {
                        this.form.rechargeMonthPrice = 0;
                        this.form.rechargeMonthPeople = 0;
                    }
                    break;
                case 'rechargeAuditDetailQuarterly':
                    this.form.rechargeQuarterlyPrice = this.quarterlyPaymentPrice * length;
                    this.form.rechargeQuarterlyPeople = length;
                    break;
                case 'rechargeAuditDetailYearly':
                    this.form.rechargeYearlyPrice = this.yearlyPaymentPrice * length;
                    this.form.rechargeYearlyPeople = length;
                    break;
            }
            // let list = this.form.serviceList;
            this.form.rechargeTotalPrice = (this.form.rechargeMonthPrice ? this.form.rechargeMonthPrice : '')
                + (this.form.rechargeQuarterlyPrice ? this.form.rechargeQuarterlyPrice : '')
                + (this.form.rechargeYearlyPrice ? this.form.rechargeYearlyPrice : '');
            this.form.rechargeTotalPeople = (this.form.rechargeMonthPeople ? this.form.rechargeMonthPeople : '')
                + (this.form.rechargeQuarterlyPeople ? this.form.rechargeQuarterlyPeople : '')
                + (this.form.rechargeYearlyPeople ? this.form.rechargeYearlyPeople : '');
        },
        addDriver(type) {
            let data = {
                driverId: '',
                driverName: '',
                mobile: '',
                carNumberPlate: '',
                carType: '',
                validityStartTime: '',
                validityEndTime: '',
                rechargePrice: '',
                validityTime: ''
            };
            this.form[type].push(data)

            this.handleSubtotal(type, this.form[type].length)
        },  // 新增司机
        deleteDriver(idx, type) {
            this.form[type].splice(idx, 1);
            this.form[type] = JSON.parse(JSON.stringify(this.form[type]))
            this.handleSubtotal(type, this.form[type].length);
        },  // 删除司机
        submitBtn(type, serveType) {  //
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    let form = JSON.parse(JSON.stringify(this.form));
                    form.payPrice = Number(form.payPrice);
                    if (!form.rechargeAuditDetailMonth.length && !form.rechargeAuditDetailQuarterly.length && !form.rechargeAuditDetailYearly.length)
                        return this.$message.error(`请添加充值${this.typeTitle}`)
                    form.rechargeAuditDetailMonth = form.rechargeAuditDetailMonth.map((item) => ({
                        ...item,
                        productType: form.productType,
                        carId: form.productType === 1 ? item.driverId : undefined,
                        driverId: form.productType === 0 ? item.driverId : undefined,
                        validityStartTime: dayjs(item.validityTime[0]).startOf('d').valueOf(),
                        validityEndTime: dayjs(item.validityTime[1]).endOf('d').valueOf()
                    }))
                    form.rechargeAuditDetailQuarterly = form.rechargeAuditDetailQuarterly.map((item) => ({
                        ...item,
                        productType: form.productType,
                        carId: form.productType === 1 ? item.driverId : undefined,
                        driverId: form.productType === 0 ? item.driverId : undefined,
                        validityStartTime: dayjs(item.validityTime[0]).startOf('d').valueOf(),
                        validityEndTime: dayjs(item.validityTime[1]).endOf('d').valueOf()
                    }))
                    form.rechargeAuditDetailYearly = form.rechargeAuditDetailYearly.map((item) => ({
                        ...item,
                        productType: form.productType,
                        carId: form.productType === 1 ? item.driverId : undefined,
                        driverId: form.productType === 0 ? item.driverId : undefined,
                        validityStartTime: dayjs(item.validityTime[0]).startOf('d').valueOf(),
                        validityEndTime: dayjs(item.validityTime[1]).endOf('d').valueOf()
                    }))
                    let res = '';
                    switch (type) {
                        case '2':
                            if (serveType === '0') res = await rechargeAuditInfoUpdate(form);
                            break;
                        default:
                            res = await rechargeAuditInfoAdd(form);
                    }
                    if (res.success && res.data) {
                        this.submitShow = false;
                        this.handleClear();
                        this.searchRole();
                        this.$router.push('/vehicleAuditList');
                    }

                } else {
                    console.log('error submit!!');
                    this.submitShow = false;
                    return false;
                }
            })
        },  // 提交  修改
        auditBtn(type) {
            let data = {
                isShow: true,
                id: this.form.id,
                payStatus: type
            }
            this.$store.dispatch('handle_dialog_audit_adopt', data)
        },
        async rechargeAuditInfoAudit(id) {
            let res = await rechargeAuditInfoDetail(id);
            if (res.success && res.data) {
                let dataInfo = JSON.parse(JSON.stringify(res.data));
                this.rechargeAuditInfo(dataInfo.rechargeAuditDetailMonth);
                this.rechargeAuditInfo(dataInfo.rechargeAuditDetailQuarterly);
                this.rechargeAuditInfo(dataInfo.rechargeAuditDetailYearly);
                this.form = {
                    ...this.form,
                    ...dataInfo
                };
                this.payStatusName = getAuditStatusLabel(this.form.payStatus).label;
                this.getDriverList(this.form.supplierId)
            }
        },  // 查看信息
        rechargeAuditInfo(list) {
            list.map(item => {
                item.carType = parseInt(item.carType);
                item.driverId = this.form.productType === 0 ? item.driverId : item.carId;
                if (item.validityStartTime) item.isRenew = true;
                else item.isRenew = false;
                item.validityTime = [new Date(item.validityStartTime), new Date(item.validityEndTime)];
            })
            return list
        }
    },
    //监听模版变量
    watch: {
        'form.operatorId'() {
            if (this.type === '0' && this.userInfo.userType === '0') {
                this.form.supplierId = ''
            }
        },
        auditId(n) {
            if (n)
                this.initData();
        }
    }

}
</script>

