<style lang="scss" scoped>
    .order-contact-name /deep/ {
        display: flex;

        .el-form-item--small.el-form-item {
            margin-bottom: 0;
        }

        .el-textarea__inner {
            padding: 5px;
        }
    }
</style>
<template>

    <div>

        <bms-dialog :isShow="dialogInfo.isShow"
                    :title="title"
                    isShowFooter
                    :width="420"
                    @update:cancelBtn="cancelBtn"
                    @update:submitBtn="submitBtn"
        >
            <div slot="content">
                <div v-if="dialogInfo.payStatus === 1">
                    我已确认申请内容，准予通过审核
                </div>
                <div class="order-contact-name" v-if="dialogInfo.payStatus === 2">
                    <el-input
                            type="textarea"
                            placeholder="请输入不通过原因"
                            v-model="remark"
                            maxlength="100"
                            resize="none"
                            show-word-limit
                            rows="6"
                    >
                    </el-input>
                </div>
            </div>
        </bms-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import bmsDialog from "@/www/components/dialog/index"
    // import {keepTwoDecimal} from '@/common/js/Utils';
    // import {
    //     createRechargeProductInfo,
    //     updateRechargeProductInfo
    // } from "@/www/urls/rechargeProductsUrls"

    import {
        rechargeAuditInfoAudit
    } from "@/www/urls/openAuditUrls"

    export default {
        //定义模版数据
        data() {
            return {
                remark: ''
            }
        },
        props: {},
        components: {
            bmsDialog,
        },
        //计算属性
        computed: {
            dialogInfo() {
                return this.$store.state.openAudit.auditAdoptDialog;
            },
            title() {
                let type = this.dialogInfo.isAdopt,
                    title = '';
                if (type === 0) title = '审核通过';
                if (type === 1) title = '审核不通过';
                return title
            }
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {

            cancelBtn() {
                this.remark = '';
                let form = {
                    isShow: false,
                };
                this.$store.dispatch('handle_dialog_audit_adopt', form);
            },
            async submitBtn() {
                let form = {
                    id: this.dialogInfo.id,
                    payStatus: this.dialogInfo.payStatus
                };

                if (this.dialogInfo.payStatus === 2) {
                    if(this.remark){
                        form.remark = this.remark;
                    } else {
                        return this.$message.error('请输入审核不通过原因');
                    }
                }

                let res = await rechargeAuditInfoAudit(form);
                if (res.success && res.data) {
                    this.$router.push('/vehicleAuditList');//
                    this.cancelBtn();
                }


            },

        },
        //监听模版变量
        watch: {}

    }
</script>

